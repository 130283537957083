import uniqid from "uniqid";
import { AnswerType, ICategory } from "../models";

export const roundHardcore: ICategory[] = [
	{
		id: uniqid(),
		name: "Ports",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "ssh + ftp",
				question: "22 + 21 = 43",
				explanation: "",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "mysql + http",
				question: "3306 + 80 = 3386",
				explanation: "",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "telnet + smtp",
				question: "23 + 25 = 48",
				explanation: "",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "dns + redis",
				question: "53 + 6379 = 6432",
				explanation: "",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "sip + git",
				question: "5060 + 9418 = 14478",
				explanation: "",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: uniqid(),
		name: "Famous Hackers",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/hacker/hacker1.jpg"),
				question: "Edward Snowden",
				explanation: "Dieser Herr hat einen der größten Geheimdienste bloßgestellt.",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/hacker/hacker2.jpg"),
				question: "Aaron Swartz",
				explanation:
					"Dieser Herr hat die sog. Frontpage des Internet mitbegründet und RSS geschrieben.",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/hacker/hacker3.jpg"),
				question: "George Hotz",
				explanation: "Dieser Herr hat iPhones aus dem Gefängnis befreit. Und Playstations.",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/hacker/hacker4.jpg"),
				question: "Tsutomu Shimomura",
				explanation:
					"Er half dem FBI Kevin Mitnik zu finden und schrieb später das Buch Takedown darüber.",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/hacker/hacker5.jpg"),
				question: "John Draper (Captain Crunch)",
				explanation:
					"Er ist ein legendärer Phone Phreak und trägt den Spitznamen eines Cornflakes Maskottchens",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: uniqid(),
		name: "JavaScript > STDOUT",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.CODE,
				answer:
					'function foo() {\n  var bar = ["t", "o", "e"].reverse().join("");\n  console.log("hello " + bar);\n}\n\nfoo();',
				question: "hello eot",
				explanation: "",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.CODE,
				answer:
					'function foo() {\n  var eot = "hello eot";\n  setTimeout(() => {\n    console.log(eot);\n  }, 100);\n  this.eot = "cheers!";\n}\n\nfoo();',
				question: "hello eot",
				explanation: "",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.CODE,
				answer:
					'function foo() {\n  var beers = 2;\n  setTimeout(() => {\n    console.log(beers + " beers");\n  }, 1000);\n\n  for (let beers = 10; beers < 10; beers++) {}\n}\n\nfoo();',
				question: "2 beers (new scope using let)",
				explanation: "",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.CODE,
				answer:
					'class Person {\n  drink = "nothing";\n  constructor(drink) {\n    this.drink = drink;\n    Person.print = function() {\n      console.log("beer");\n    };\n  }\n  print() {\n    console.log(this.drink);\n  }\n}\nconst person = new Person("water");\nperson.print();',
				question: "water",
				explanation: "",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.CODE,
				answer:
					'const debug = console.log;\nconst warn = console.warn;\nfunction eat(snack, logger) {\n  // TODO: use logger\n  debug(snack);\n  if (snack) {\n    logger(snack);\n  } else if (snack === "cookie") {\n    logger("cookie");\n  }\n  return snack;\n}\nconsole.log(eat("cookie", warn));',
				question: "2x cookie",
				explanation: "",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: "1qxjh6uk1sbd7yg",
		name: "IPs and Networks",
		answers: [
			{
				id: "1qxjh6uk1sbd7yn",
				type: AnswerType.TEXT,
				answer: "The number of bytes in an IPv4 address",
				answerDe: "Die Anzahl der Bytes in einer IPv4-Adresse",
				question: "What is 4",
				explanation: "",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: "1qxjh6uk1sbd7yu",
				type: AnswerType.TEXT,
				answer: "Private IPv4 addresses were set as a standard in this RFC.",
				answerDe: "Private IPv4-Adressen wurden in diesem RFC als Standard festgelegt.",
				question: "What is RFC 1918",
				explanation: "",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: "1qxjh6uk1sbd7z1",
				type: AnswerType.TEXT,
				answer: "A netmask of 255.255.255.240 (or /28) has this many usable hosts",
				answerDe:
					"Eine Netzmaske von 255.255.255.255.240.240 (oder /28) hat so viele brauchbare Hosts.",
				question: "What is 14",
				explanation: "",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: "1qxjh6uk1sbd7z8",
				type: AnswerType.TEXT,
				answer:
					"This technology is known for securing Internet Protocol (IP) communications by authenticating and/or encrypting each IP packet of a communication session",
				answerDe:
					"Diese Technologie ist bekannt für die Sicherung der Internet Protocol (IP)-Kommunikation durch Authentifizierung und/oder Verschlüsselung jedes IP-Pakets einer Kommunikationssitzung.",
				question: "What is IPSec",
				explanation: "",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: "1qxjh6uk1sbd7zf",
				type: AnswerType.TEXT,
				answer: "The subnet 224.0.0.0/4 is reserved for this use",
				answerDe: "Das Subnetz 224.0.0.0.0.0/4 ist für diese Nutzung reserviert.",
				question: "What is IP Multicast",
				explanation: "",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: uniqid(),
		name: "Hacker Movies",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/movies/hacker_movies1.jpg"),
				question: "Jurassic Park",
				explanation: "Samuel L. Jackson ist dabei und sagt: Ich hasse dieser Hacker scheisse!",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/movies/hacker_movies2.png"),
				question: "Iron Man 2",
				explanation:
					"Es ist ein Marvel Film und <spoiler>Justin Hammer</spoiler> sieht hier ganz schlecht aus...",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/movies/hacker_movies3.jpg"),
				question: "WarGames",
				explanation: "Ein uralter Film in dem es um den Atomkrieg geht",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/movies/hacker_movies4.jpg"),
				question: "TRON: Legacy",
				explanation: "Jeder von euch hat ihn gesehen und hört die Musik auch ziemlich gern",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/movies/hacker_movies5.jpg"),
				question: "Matrix 2",
				explanation:
					"Hier sehen wir einen echten SSH-Nuke Exploit. Es wird ein Kraftwerk herunter gefahren.",
				points: 1000,
				show: false,
				answered: false
			}
		]
	}
];
