.root {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.routerWrapper {
	flex: 1;
	height: 100%;
}
