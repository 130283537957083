import uniqid from "uniqid";
import {AnswerType, ICategory} from "../models";

export const roundSipgate: ICategory[] = [
	{
		id: uniqid(),
		name: "sipgate Kunst",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Diese Katze hängt dank Lin May Saeed bei vielen sipgatys zu Hause",
				question: "Was ist der Serval?",
				explanation: "",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Dieses \"Reittier\" ist aktuell im Eingangsbereich des Büros zu finden",
				question: "Was ist eine Wurst?",
				explanation: "",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Dieses Material ziert die Oberkante des größten Gemäldes in der sipgate-Sammlung",
				question: "Was ist Paket-/Klebeband",
				explanation: "",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Dieses ungewöhnliche Material macht die Werke von Sofie Reinhold extrem glatt",
				question: "Was ist Marmormehl?",
				explanation:
					"",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Dieser Künstler erfüllt mit seinen Fotografien bei sipgate gewissermaßen eine \"Aufsichtspflicht\"",
				question: "Wer ist Andreas Gefeller?",
				explanation:
					"",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: uniqid(),
		name: "sipgate Teams",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Dieser Frau legt gerne alles auf die Goldwaage und trägt trotz mangelndem Sehvermögen scharfkantige Gegenstände bei sich",
				question: "Wer ist Justizia?",
				explanation:
					"",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Dieses Team ist quasi das Mitochondrium von sipgate",
				question: "Was ist Kraftwerk 3000?",
				explanation:
					"",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Diese Zwillingsschwestern sind die Töchter von Jacqueline and Clancy Bouvier",
				question: "Wer sind Patty und Selma?",
				explanation: "",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Dieses Team verdankt seinen Namen indirekt dem einer Audiodatei",
				question: "Wer sind die Monkeys ohne Namen?",
				explanation:
					"",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Dieses schwarz-weiße Tier ist nicht, was sein Name vermuten lässt",
				question: "Was ist der Honigdachs/Honey Badger?",
				explanation: "",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: uniqid(),
		name: "Gemischtes aus dem sipgate",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Danach waren zwischenzeitlich die Cubes auf dem Parkplatz benannt",
				question: "Was sind Museen?",
				explanation:
					"",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Dieser Meeressäuger ziert das Banner von sipgates erfolgreichster Karaoke-Veranstaltung",
				question: "Was ist der Orka?",
				explanation:
					"",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Auf dieser Straße lag das vorherige Büro von sipgate",
				question: "Was ist die Ronsdorfer Straße?",
				explanation: "",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Dieser sipgate-Gesellschaft gehört ein Flugzeug",
				question: "Was ist die Cloud Digger?",
				explanation:
					"",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: "Diesen Effekt bewirbt Stefan Lange-Hegermann im meistegesehenen sipgate-Video bei YouTube",
				question: "Was ist der WOW-Effekt?",
				explanation: "",
				points: 1000,
				show: false,
				answered: false
			}
		]
	}
];
