"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * List of string constants to represent different props
 */
var LOADING = "loading";
var COLOR = "color";
var CSS = "css";
var SIZE = "size";
var SIZE_UNIT = "sizeUnit";
var WIDTH = "width";
var WIDTH_UNIT = "widthUnit";
var HEIGHT = "height";
var HEIGHT_UNIT = "heightUnit";
var RADIUS = "radius";
var RADIUS_UNIT = "radiusUnit";
var MARGIN = "margin";
var commonValues = (_a = {},
    _a[LOADING] = true,
    _a[COLOR] = "#000000",
    _a[CSS] = {},
    _a);
var heightWidthValues = function (height, width) {
    var _a;
    return (_a = {},
        _a[HEIGHT] = height,
        _a[HEIGHT_UNIT] = "px",
        _a[WIDTH] = width,
        _a[WIDTH_UNIT] = "px",
        _a);
};
var sizeValues = function (sizeValue) {
    var _a;
    return (_a = {},
        _a[SIZE] = sizeValue,
        _a[SIZE_UNIT] = "px",
        _a);
};
exports.sizeDefaults = function (sizeValue) {
    return Object.assign({}, commonValues, sizeValues(sizeValue));
};
exports.sizeMarginDefaults = function (sizeValue) {
    var _a;
    return Object.assign({}, exports.sizeDefaults(sizeValue), (_a = {},
        _a[MARGIN] = "2px",
        _a));
};
exports.heightWidthDefaults = function (height, width) {
    return Object.assign({}, commonValues, heightWidthValues(height, width));
};
exports.heightWidthRadiusDefaults = function (height, width, radius) {
    var _a;
    if (radius === void 0) { radius = 2; }
    return Object.assign({}, exports.heightWidthDefaults(height, width), (_a = {},
        _a[RADIUS] = radius,
        _a[RADIUS_UNIT] = "px",
        _a[MARGIN] = "2px",
        _a));
};
