.root {
	display: flex;
	flex-direction: column;
	height: 100vh;
	background-color: black;
	/* padding: 12px 12px 0 12px; */
	align-items: center;
	padding: 10px;
}

.playerButton {
	width: 100%;
	height: 100%;
	/* border: 10px solid black; */
	/* margin-bottom: 12px; */
	border: none;
	font-size: 30px;
}

.playerButton:active {
	opacity: 0.6;
}

.teamInputWrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
}

.teamInput {
	padding: 12px 24px;
	width: 100%;
	margin-bottom: 12px;
	border: 4px solid black;
	background-color: #0618bd;
}

.teamInput::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #c7f533;
	opacity: 1; /* Firefox */
}

.teamInputButton {
	background-color: #0618bd;
	border: 4px solid black;
	padding: 12px 24px;
	width: 100%;
	color: #c7f533;
}

.teamHeadline {
	font-size: 20px;
}

.userId {
	font-size: 12px;
	margin-bottom: 5px;
	opacity: 0.5;
}

.qrReader {
	text-align: center;
	width: 100%;
	max-width: 400px;
	max-height: 500px;
}

.helpline {
	padding: 10px;
	font-size: 20px;
}

.playerScreenInput {
	/* padding: 12px 24px; */
	width: 100%;
	font-size: 30px;
	text-align: center;
	position: absolute;
	/* bottom: 50px; */
	z-index: 9999;
	/* user-select: none; */
	top: 50%;
}

.playerScreenInput:focus {
	outline: none;
	border: none;
}
.playerScreenInput:active {
	border: none;
}

.loadingView {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #060ce9;
}

.loadingView p {
	color: #ffd700;
}

.loader {
	/* border: 1px solid red; */
	width: 135px;
}

.answerInfo {
	padding: 10px;
	font-size: 20px;
	text-align: center;
	margin: 10px 0;
	width: 100%;
	background-color: #060ce9;
}
