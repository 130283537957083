.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	background-color: #060ce9;
	justify-content: space-between;
}

.title {
	width: 100%;
	vertical-align: middle;
	padding: 10px 20px;
	/* border-bottom: 2px solid white; */
	color: white;
	height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.titleText {
	font-size: 20px;
	text-align: center;
	display: flex;
	flex-direction: column;
}

.titleName {
	font-weight: bold;
}

.categories {
	display: flex;
	flex-wrap: no-wrap;
	width: 100%;
	justify-content: center;
	padding: 0 20px;
}

.category {
	border: 1px solid gold;
	flex: 1;
}

.categoryTitle {
	color: gold;
	font-size: 30px;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	padding: 10px;
	height: 100px;
}

.answer {
	color: white;
	border-top: 1px solid gold;
	padding: 30px 30px;
	text-align: center;
	font-size: 40px;
	font-weight: bold;
	height: 100px;
}

.teamFooter {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	width: 100%;
}

.teamItem {
	padding: 10px;
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	flex-basis: 25%;
	align-items: center;
}

@keyframes shadow-pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.8);
	}
	100% {
		box-shadow: 0 0 0 35px rgba(255, 255, 255, 0);
	}
}

.teamItemActive {
	z-index: 10000;
	animation: shadow-pulse 1s infinite;
}

.titleFirst {
	font-size: 30px;
}

.teamName {
	font-size: 30px;
	padding-left: 20px;
	text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
}

.teamPoints {
	font-size: 50px;
	margin-left: 20px;
	font-weight: bold;
	text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
}

.text {
	font-size: 60px;
	max-width: 800px;
}

.code {
	text-align: left;
	font-size: 40px;
}

.image {
	max-height: 600px;
	height: 100%;
}

.categoryTitle {
	color: gold;
	padding: 30px 30px;
	border-bottom: 2px solid gold;
	font-size: 30px;
	text-align: center;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
}

.answerTitle {
	color: gold;
	font-size: 30px;
	text-align: center;
	margin-bottom: 20px;
	font-weight: bold;
}

.confettiWrapper {
	position: absolute;
	top: 0;
	left: 0;
}
