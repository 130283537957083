import uniqid from "uniqid";
import { AnswerType, ICategory } from "../models";

export const roundTech: ICategory[] = [
	{
		id: uniqid(),
		name: "TLDs",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: ".gov",
				question: "Goverment",
				explanation: "Wird überwiegend von der Amerikanischen Regierung eingesetzt",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: ".to",
				question: "Tonga",
				explanation: "Die Inselgruppe wurde früher auch Freundschaftsinseln genannt.",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: ".tv",
				question: "Tuvalu",
				explanation:
					"Bis zur Unabhängigkeit am 1. Oktober 1978 hieß das Territorium Ellice Islands.",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: ".mc",
				question: "Monaco",
				explanation:
					"Heute ist das Fürstentum vornehmlich bei den Reichen der Welt als Wohnsitz begehrt",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.TEXT,
				answer: ".za",
				question: "Zuid Afrika",
				explanation:
					"Es ist der am weitesten entwickelte Wirtschaftsraum des afrikanischen Kontinents",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: uniqid(),
		name: "Hello World",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.CODE,
				answer:
					'#include <stdio.h>\n#include <stdlib.h>\n\nint main(void)\n{\n        printf("hello, world");\n        return EXIT_SUCCESS;\n}',
				question: "C",
				explanation:
					"Zahlreiche Sprachen orientieren sich an der Syntax und anderen Eigenschaften von dieser Sprache",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.CODE,
				answer: 'require "sinatra"\nget "/hi" do\n        "Hello World!"\nend',
				question: "Ruby",
				explanation:
					"Diese höhere Programmiersprache wurde Mitte der 1990er Jahre vom Japaner Yukihiro Matsumoto entworfen",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.CODE,
				answer: 'tell application "Microsoft Word"\n        quit\nend tell',
				question: "Apple Script",
				explanation: "Die erste Version dieser Sprache basierte auf dem HyperCard-Projekt",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.CODE,
				answer: "print join ' ', reverse split ' ', reverse join ' ', q/teY rehtona lreP .rekcah/;",
				question: "Perl",
				explanation:
					"Der Linguist Larry Wall entwarf sie 1987 als Synthese aus C, awk, den Unix-Befehlen und anderen Einflüssen",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.CODE,
				answer: "",
				question: "Whitespace",
				explanation: "Der Code hier ist syntaktisch korrekt und gibt auch wirklich hello world aus",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: uniqid(),
		name: "Websites",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/websites/websites1.png"),
				question: "PornHub",
				explanation: "2018 hatte diese Platform 33 Milliarden Visits",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/websites/websites2.png"),
				question: "GitHub",
				explanation: "Diese Platform wurde in ruby on rails und elixir geschrieben",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/websites/website4.png"),
				question: "StackOverflow",
				explanation: "250 Leute schmeißen diese Webseite",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/websites/websites4.png"),
				question: "SpaceX",
				explanation: "Irgendwo auf der Website steht, dass ihr nur max. 64 Tonnen mitnehmen könnt.",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/websites/websites5.gif"),
				question: "Coderanch",
				explanation: "Wenn Stackoverflow mal down ist, kommt ihr meistens hier raus",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: uniqid(),
		name: "Unix",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/unix/unix1.jpg"),
				question: "cat",
				explanation: "Wie heißt das UNIX Kommando?",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/unix/unix2.jpg"),
				question: "tail",
				explanation: "Wir suchen ein UNIX Kommando zu diesem Bild",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/unix/unix3.gif"),
				question: "ping",
				explanation:
					"Ich weiß ja nicht wie ihr eure Infrastruktur monitored aber wir machen das mit dem Tool",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/unix/unix4.jpg"),
				question: "netcat",
				explanation: "Die meisten benutzen es mit -tulpen",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/unix/unix5.jpg"),
				question: "socat",
				explanation: "Wer von euch hatte noch ein Modem oder ist ein UNIX Opa?",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: "1qxjj4uk1scgk0c",
		name: "Hacker Crews",
		answers: [
			{
				id: "1qxjj4uk1scgk0h",
				type: AnswerType.TEXT,
				answer: "Cyper, Tank, Switch, Trinity, Morpheus, Neo",
				question: "What is 'The Matrix'?",
				explanation: "Die Sonnenbrille für jeden Charakter wurde individuell gestaltet.",
				hint: "Bugs wurden hier nett mit Deja vu umschrieben",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: "1qxjj4uk1scgk0m",
				type: AnswerType.TEXT,
				answer: "Eliot Anderson, Darlene, Tyrell Wellick, fsociety",
				question: "What is 'Mr. Robot'?",
				explanation: "",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: "1qxjj4uk1scgk0r",
				type: AnswerType.TEXT,
				answer: "The Plague, Phantom Phreak, Crash Override, Zero Cool, Acid Burn",
				question: "What is 'Hackers'?",
				explanation: "",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: "1qxjj4uk1scgk0w",
				type: AnswerType.TEXT,
				answer: "Batou, Togusa, Saito, Ishikawa, Motoko ('The Major')",
				question: "What is 'Ghost in the Shell'?",
				explanation: "",
				hint: "Puppeteer hochgehen lassen, Anime, Realverfilmung",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: "1qxjj4uk1scgk11",
				type: AnswerType.TEXT,
				answer: "Count Zero, Brian Oblivion, Space Rogue, Kingpin, Mudge",
				question: "What is L0pht?",
				explanation:
					"Die Pioniere des responsible disclosure, Passwort brute force tool geschrieben",
				points: 1000,
				show: false,
				answered: false
			}
		]
	}
];
