.root {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.controlBar {
	width: 100%;
	padding: 10px;
	background-color: grey;
	border-bottom: 2px solid gold;
}

.controlBar button {
	margin-right: 10px;
}

.content {
	display: flex;
	flex-grow: 1;
	height: 100%;
}

.categories {
	background-color: blue;
	border-right: 2px solid gold;
	color: white;
	padding: 10px;
	overflow-y: scroll;
	min-width: 400px;
	height: 100%;
}

.teams {
	flex-grow: 1;
}

.rightColumn {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.currentAnswer {
	background-color: blue;
	flex-grow: 1;
	color: white;
}

.team {
	padding: 10px;
}

.teamName {
	font-size: 20px;
	font-weight: bold;
}

.pointsbutton {
	font-size: 20px;
	margin-right: 10px;
}

.currentAnswerTitle {
	font-size: 20px;
}

.points {
	font-size: 18px;
	width: 50px;
	font-weight: 600;
}

.answerRow {
	display: flex;
	align-items: center;
}

.answerRow button {
	margin-left: 10px;
}

.timer {
	font-size: 30px;
	background-color: white;
	padding: 20px;
	color: black;
	width: 100px;
}

.awardings {
	margin-top: 20px;
}
