import { useState, useEffect } from "react";
import Md5 from "blueimp-md5";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const useUserId = () => {
	const [userId, setUserId] = useState<string>("");

	useEffect(() => {
		// Initialize an agent at application startup.
		const load = async () => {
			const fp = await FingerprintJS.load();

			// Get the visitor identifier when you need it.
			fp.get().then(result => {
				// This is the visitor identifier:
				const visitorId = Md5(result.visitorId);
				setUserId(visitorId);
			});
		};

		load();
	}, []);

	return userId;
};
