import uniqid from "uniqid";
import { AnswerType, ICategory } from "../models";

export const roundGames: ICategory[] = [
	{
		id: "sktwiyw5k1tjf6ni",
		name: "Most popular games",
		answers: [
			{
				id: "sktwiyw5k1tjf6nn",
				type: AnswerType.TEXT,
				answer: 'This game features a man named "Drake" who searches for treasure around the world',
				answerDe:
					'In diesem Spiel wird ein Mann namens "Drake" gespielt, der auf der ganzen Welt nach Schätzen sucht.',
				question: "What is Uncharted 4",
				explanation: "",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: "sktwiyw5k1tjf6ns",
				type: AnswerType.TEXT,
				answer:
					"This game takes place in 2287 and a nuke goes off as you are going down an elevator",
				answerDe:
					"Dieses Spiel findet im Jahr 2287 statt und eine Atombombe geht los, während man mit einem Aufzug fährt.",
				question: "What is Fallout 4",
				explanation: "",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: "sktwiyw5k1tjf6nx",
				type: AnswerType.TEXT,
				answer:
					"This game allows you to be a criminal mastermind and run in the city of Los Santos",
				answerDe:
					"Dieses Spiel erlaubt es dir, ein kriminelles Superhirn zu sein und in der Stadt Los Santos zu laufen.",
				question: "What is GTA V",
				explanation: "",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: "sktwiyw5k1tjf6o2",
				type: AnswerType.TEXT,
				answer: "This game takes place in London England in the time of 1868",
				answerDe: "Dieses Spiel findet in London England in der Zeit von 1868 statt",
				question: "What is Assassin's Creed Syndicate",
				explanation: "",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: "sktwiyw5k1tjf6o7",
				type: AnswerType.TEXT,
				answer: 'You play this game as a man named "Joel" and a little girl named "Ellie"',
				answerDe:
					'Du spielst dieses Spiel als ein Mann namens "Joel" und ein kleines Mädchen namens "Ellie".',
				question: "What is The Last of Us",
				explanation: "",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: "1qxjj0ek1sccaif",
		name: "Popular Board games",
		answers: [
			{
				id: "1qxjj0ek1sccaik",
				type: AnswerType.TEXT,
				answer:
					"A game in which you are a entrepreneur buying properties in order to expand your wealth. ",
				answerDe:
					"Ein Spiel, in dem Sie als Unternehmer Immobilien kaufen, um Ihr Vermögen zu erweitern.",
				question: "What is Monopoly? ",
				explanation: "",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: "1qxjj0ek1sccaip",
				type: AnswerType.TEXT,
				answer:
					"A game about bringing all your pawns home,it is remembered for its iconic saying when you screw someone over. ",
				answerDe: "Ein Spiel, bei dem es darum geht, alle Püppchen nach Hause zu bringen",
				question: "What is Sorry? / Mensch ärger dich nicht",
				explanation: "",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: "1qxjj0ek1sccaiu",
				type: AnswerType.TEXT,
				answer:
					"A game about losing all your cards by playing the same number or color down on a discard pile.",
				answerDe:
					"Ein Spiel um den Verlust aller Ihrer Karten, indem Sie die gleiche Zahl oder Farbe auf einem Ablagestapel spielen.",
				question: "What is Uno?",
				explanation: "",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: "1qxjj0ek1sccaiz",
				type: AnswerType.TEXT,
				answer: "A classic game about finding a murderer through searching a house.",
				answerDe: "Ein klassisches Spiel über die Suche nach einem Mörder in einem Haus.",
				question: "What is Clue?",
				explanation: "",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: "1qxjj0ek1sccaj4",
				type: AnswerType.TEXT,
				answer:
					"A game about through the process of elimination finding out which character your opponent is",
				answerDe:
					"Ein Spiel über den Prozess der Eliminierung, um herauszufinden, welcher Charakter dein Gegner ist.",
				question: "What is Guess Who?",
				explanation: "",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: "1qxjj0ek1sccaii",
		name: "Video Game Characters",
		answers: [
			{
				id: "1qxjj0ek1sccain",
				type: AnswerType.TEXT,
				answer: "A fat Italian plumber's pet.",
				answerDe: "Das Haustier von einem fetten italienischen Klempner.",
				question: "What is Yoshi?",
				explanation: "",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: "1qxjj0ek1sccais",
				type: AnswerType.TEXT,
				answer: "A famous hero who wears green, A.K.A the Hero of Time.",
				answerDe: "Ein berühmter Held, der grün trägt, A.K.A. der Hero of Time.",
				question: "Who is Link?",
				explanation: "",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: "1qxjj0ek1sccaj2",
				type: AnswerType.TEXT,
				answer: "A pink character who can suck enemies up and gain their abilities.",
				answerDe: "Ein rosa Charakter, der Feinde aufsaugen und ihre Fähigkeiten annehmen kann.",
				question: "Who is Kirby?",
				explanation: "",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: "1qxjj0ek1sccaix",
				type: AnswerType.TEXT,
				answer:
					"A Female character genetically modified with bird D.N.A welding a charge up ion cannon.",
				answerDe:
					"Ein weiblicher Charakter, der mit Vogel D.N.A. genetisch verändert wurde und eine aufgeladene lonenkanone hat.",
				question: "Who is Samus Aran?",
				explanation: "",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: "1qxjj0ek1sccaj7",
				type: AnswerType.TEXT,
				answer: "A Humanoid robot wearing blue fighting an insane doctor.",
				answerDe: "Ein blauer, humanoider Roboter kämpft gegen einen verrückten Arzt.",
				question: "Who is Megaman?",
				explanation: "",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: uniqid(),
		name: "Guess the game",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/games/games1.jpg"),
				question: "Pacman",
				explanation: "",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/games/games2.jpg"),
				question: "Monkey Island",
				explanation: "",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/games/games3.jpg"),
				question: "Pong",
				explanation: "",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/games/games4.gif"),
				question: "Game of Life",
				explanation: "",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/games/games5.jpg"),
				question: "Zork",
				explanation: "",
				points: 1000,
				show: false,
				answered: false
			}
		]
	},
	{
		id: uniqid(),
		name: "Minecraft",
		answers: [
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/minecraft/minecraft1.jpg"),
				question: "Clock/Uhr",
				explanation: "Was bekommt man, wenn man dieses Rezept benutzt?",
				points: 200,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/minecraft/minecraft2.png"),
				question: "Beacon/Leuchtfeuer",
				explanation: "Was bekommt man, wenn man dieses Rezept benutzt?",
				points: 400,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				answer:
					require("url:../assets/answers/minecraft/minecraft3.png"),
				question: "Hopper/Trichter",
				explanation: "Was bekommt man, wenn man dieses Rezept benutzt?",
				points: 600,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				question: "Repeater/Redstone-Verstärker",
				answer:
					require("url:../assets/answers/minecraft/minecraft4.png"),
				explanation: "Was bekommt man, wenn man dieses Rezept benutzt?",
				points: 800,
				show: false,
				answered: false
			},
			{
				id: uniqid(),
				type: AnswerType.IMAGE,
				question: "Observer/Beobachter",
				answer:
					require("url:../assets/answers/minecraft/minecraft5.png"),
				explanation: "Was bekommt man, wenn man dieses Rezept benutzt?",
				points: 1000,
				show: false,
				answered: false
			}
		]
	}
];
